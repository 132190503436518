import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classes from "./Dashboard.module.css";
import {
  getProjectUpdates,
  addProjectUpdate,
} from "../../../../redux/actions/adminActions";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Modal, Card, Form, Button } from "react-bootstrap";
import { NotificationManager } from "react-notifications";

const ProjectUpdate = props => {
  const [updates, setUpdates] = useState({});
  const [state, setState] = useState({
    disableBtn: false,
    showUpdateModal: false,
    errors: "",
  });
  const inputUpdate = useRef("");

  useEffect(() => {
    triggerGetProjectUpdates();
  }, [props.projectId]);

  const triggerGetProjectUpdates = () => {
    getProjectUpdates(props.projectId)
      .then(res => {
        setUpdates(res.data);
      })
      .catch(err => console.log(err));
  };

  const handleAddUpdate = updateText => {
    updateText = updateText.trim();
    if (updateText === "") {
      alert("Update text is required.");
      return false;
    }

    addProjectUpdate({ projectId: props.projectId, updateText })
      .then(res => {
        handleCloseModal();
        if (res.data.success) {
          triggerGetProjectUpdates();
          createNotification("added");
        }
      })
      .catch(err => {
        handleCloseModal();
        createNotification("error");
        console.error(err);
      });
  };

  const handleShowModal = () => {
    setState(prevState => {
      return {
        ...prevState,
        showUpdateModal: true,
      };
    });
  };

  const handleCloseModal = () => {
    setState(prevState => {
      return {
        ...prevState,
        showUpdateModal: false,
      };
    });
  };

  const createNotification = (type, message = false) => {
    switch (type) {
      case "added":
        NotificationManager.success(
          "You have successfully added the update.",
          "Update Added",
          4000
        );
        break;
      case "error":
        NotificationManager.error(
          message || "Something went wrong!",
          "Error",
          5000
        );
        break;
      default:
    }
  };

  const columnsProjectUpdate = [
    {
      dataField: "timestamp",
      text: "DATE",
      sort: true,
      classes: "breakWord",
      headerStyle: (column, colIndex) => {
        return { width: "110px" };
      },
    },
    {
      dataField: "message",
      text: "UPDATE",
      classes: "breakWord",
    },
    {
      dataField: "actionedBy",
      text: "ACTIONED BY",
      classes: "breakWord",
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "110px" };
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "timestamp",
      order: "desc",
    },
  ];

  const pagination = paginationFactory({
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
    ],
  });

  let dataProjectUpdate = [];
  if (!!updates) {
    for (let i = 0; i < updates.length; i++) {
      if (Number.isInteger(updates[i].timestamp)) {
        updates[i].timestamp = moment(updates[i].timestamp * 1000)
          .format("YYYY/MM/DD HH:mm:ss")
          .toString();
      }

      if (updates[i].customName) updates[i].actionedBy = updates[i].customName;

      dataProjectUpdate.push(updates[i]);
    }
  }

  return (
    <>
      <Card className={classes.card}>
        <Card.Body className="p-0 pb-2">
          <p
            className={[
              "p-3 m-0 border-bottom fontWeight500",
              classes.cardHeader,
            ].join(" ")}
          >
            Project Updates
            <Button
              type="button"
              variant="link"
              className="smallGreenButton mt-1 px-2 text-white"
              onClick={handleShowModal}
            >
              + ADD
            </Button>
          </p>
          <BootstrapTable
            bootstrap4
            bordered={false}
            keyField="_id"
            data={dataProjectUpdate}
            columns={columnsProjectUpdate}
            defaultSorted={defaultSorted}
            hover={true}
            pagination={pagination}
            classes={classes.table}
            headerClasses={classes.tableHeaderClass}
          />
        </Card.Body>
      </Card>

      <Modal show={state.showUpdateModal} onHide={handleCloseModal} size="md">
        <Modal.Header closeButton>
          <Modal.Title>Add entry</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form.Group>
            <Form.Control type="text" ref={inputUpdate} />
          </Form.Group>

          <Form.Row className="justify-content-between">
            <Button
              className="blueButton font-weight-normal mx-4"
              disabled={state.disableBtn}
              onClick={() => handleAddUpdate(inputUpdate.current.value)}
            >
              Submit
            </Button>

            <Button
              className="orangeButton text-dark font-weight-normal mx-4"
              disabled={state.disableBtn}
              onClick={handleCloseModal}
            >
              Discard
            </Button>
          </Form.Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

ProjectUpdate.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps)(ProjectUpdate);
