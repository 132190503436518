import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../assets/images/FDB_logo_new.svg";
import SidebarCounter from "../SidebarCounter/SidebarCounter";

const SidebarAdmin = props => {
  const [activeItem, setActiveItem] = useState("");

  useEffect(() => {
    setActiveItem(window.location.pathname);
  }, []);

  const handleMenuItemClicked = destination => {
    setActiveItem(destination);
  };

  return (
    <>
      <img src={logo} className="sidebarLogo sidebarLogoAdmin" alt="FraudDB" />

      <div className="sidebarUser">
        <div className="menuItemTitle">USER MANAGEMENT</div>
        <Button
          as={Link}
          to="/users/all"
          onClick={() => handleMenuItemClicked("/users/all")}
          className={[
            "menuItem py-2",
            activeItem.includes("/users/all") ? "active" : null,
          ]}
        >
          All Users
        </Button>
        <Button
          as={Link}
          to="/users/new"
          onClick={() => handleMenuItemClicked("/users/new")}
          className={[
            "menuItem py-2",
            activeItem.includes("/users/new") ? "active" : null,
          ]}
        >
          New Users
          {props.global.newRequests.newUsers > 0 ? (
            <SidebarCounter number={props.global.newRequests.newUsers} />
          ) : null}
        </Button>
        <Button
          as={Link}
          to="/users/approved"
          onClick={() => handleMenuItemClicked("/users/approved")}
          className={[
            "menuItem py-2",
            activeItem.includes("/users/approved") ? "active" : null,
          ]}
        >
          Approved Users
        </Button>
        <Button
          as={Link}
          to="/users/rejected"
          onClick={() => handleMenuItemClicked("/users/rejected")}
          className={[
            "menuItem py-2",
            activeItem.includes("/users/rejected") ? "active" : null,
          ]}
        >
          Rejected Users
        </Button>

        <div className="menuItemTitle">LEGAL MANAGEMENT</div>
        <Button
          as={Link}
          to="/legals/lawyers"
          onClick={() => handleMenuItemClicked("/legals/lawyers")}
          className={[
            "menuItem py-2",
            activeItem.includes("/legals/lawyers") ? "active" : null,
          ]}
        >
          Lawyers
        </Button>
        <Button
          as={Link}
          to="/legals/companies"
          onClick={() => handleMenuItemClicked("/legals/companies")}
          className={[
            "menuItem py-2",
            activeItem.includes("/legals/companies") ? "active" : null,
          ]}
        >
          Companies
        </Button>

        <div className="menuItemTitle">CLAIMS</div>
        <Button
          as={Link}
          to="/claims/draft"
          onClick={() => handleMenuItemClicked("/claims/draft")}
          className={[
            "menuItem py-2",
            activeItem === "/claims/draft" ? "active" : null,
          ]}
        >
          Draft Claims
          {props.global.newRequests.draftClaims > 0 ? (
            <SidebarCounter number={props.global.newRequests.draftClaims} />
          ) : null}
        </Button>
        <Button
          as={Link}
          to="/claims/new"
          onClick={() => handleMenuItemClicked("/claims/new")}
          className={[
            "menuItem py-2",
            activeItem === "/claims/new" ? "active" : null,
          ]}
        >
          New Claims
          {props.global.newRequests.newClaims > 0 ? (
            <SidebarCounter number={props.global.newRequests.newClaims} />
          ) : null}
        </Button>
        <Button
          as={Link}
          to="/claims/approved"
          onClick={() => handleMenuItemClicked("/claims/approved")}
          className={[
            "menuItem py-2",
            activeItem === "/claims/approved" ? "active" : null,
          ]}
        >
          Approved Claims
        </Button>
        <Button
          as={Link}
          to="/claims/pending"
          onClick={() => handleMenuItemClicked("/claims/pending")}
          className={[
            "menuItem py-2",
            activeItem === "/claims/pending" ? "active" : null,
          ]}
        >
          Pending Claims
          {props.global.newRequests.pendingClaims > 0 ? (
            <SidebarCounter number={props.global.newRequests.pendingClaims} />
          ) : null}
        </Button>
        <Button
          as={Link}
          to="/claims/returned"
          onClick={() => handleMenuItemClicked("/claims/returned")}
          className={[
            "menuItem py-2",
            activeItem === "/claims/returned" ? "active" : null,
          ]}
        >
          Returned Claims
        </Button>
        <Button
          as={Link}
          to="/claims/rejected"
          onClick={() => handleMenuItemClicked("/claims/rejected")}
          className={[
            "menuItem py-2",
            activeItem === "/claims/rejected" ? "active" : null,
          ]}
        >
          Rejected Claims
        </Button>

        <div className="menuItemTitle">PROJECTS</div>
        <Button
          as={Link}
          to="/projects/new"
          onClick={() => handleMenuItemClicked("/projects/new")}
          className={[
            "menuItem py-2",
            activeItem === "/projects/new" ? "active" : null,
          ]}
        >
          New Projects
          {props.global.newRequests.newProjects > 0 ? (
            <SidebarCounter number={props.global.newRequests.newProjects} />
          ) : null}
        </Button>
        <Button
          as={Link}
          to="/projects/approved"
          onClick={() => handleMenuItemClicked("/projects/approved")}
          className={[
            "menuItem py-2",
            activeItem === "/projects/approved" ? "active" : null,
          ]}
        >
          Approved Projects
        </Button>
        <Button
          as={Link}
          to="/projects/prereserved"
          onClick={() => handleMenuItemClicked("/projects/prereserved")}
          className={[
            "menuItem py-2",
            activeItem === "/projects/prereserved" ? "active" : null,
          ]}
        >
          Prereserved Projects
          {props.global.newRequests.prereservedProjects > 0 ? (
            <SidebarCounter
              number={props.global.newRequests.prereservedProjects}
            />
          ) : null}
        </Button>
        <Button
          as={Link}
          to="/projects/reserved"
          onClick={() => handleMenuItemClicked("/projects/reserved")}
          className={[
            "menuItem py-2",
            activeItem === "/projects/reserved" ? "active" : null,
          ]}
        >
          Reserved Projects
          {props.global.newRequests.reservedProjects > 0 ? (
            <SidebarCounter
              number={props.global.newRequests.reservedProjects}
            />
          ) : null}
        </Button>
        <Button
          as={Link}
          to="/projects/inprocess"
          onClick={() => handleMenuItemClicked("/projects/inprocess")}
          className={[
            "menuItem py-2",
            activeItem === "/projects/inprocess" ? "active" : null,
          ]}
        >
          Projects in Process
          {props.global.newRequests.inprocessProjects > 0 ? (
            <SidebarCounter
              number={props.global.newRequests.inprocessProjects}
            />
          ) : null}
        </Button>
        <Button
          as={Link}
          to="/projects/resolved"
          onClick={() => handleMenuItemClicked("/projects/resolved")}
          className={[
            "menuItem py-2",
            activeItem === "/projects/resolved" ? "active" : null,
          ]}
        >
          Resolved Projects
        </Button>
        <Button
          as={Link}
          to="/projects/rejected"
          onClick={() => handleMenuItemClicked("/projects/rejected")}
          className={[
            "menuItem py-2",
            activeItem === "/projects/rejected" ? "active" : null,
          ]}
        >
          Rejected Projects
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  global: state.global,
});

export default connect(mapStateToProps)(SidebarAdmin);
