import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getProject } from "../../../../redux/actions/adminActions";
import { Button, Col, Container, Row } from "react-bootstrap";
import { isMobileOnly } from "react-device-detect";
import ProjectBar from "./ProjectBar";
import Claimants from "./Claimants";
import ProjectData from "./ProjectData";
import ProjectAssignment from "./ProjectAssignment";
import ProjectLogs from "./ProjectLogs";
import ProjectUpdates from "./ProjectUpdates";
import UserClaims from "./UserClaims";
import Statuses from "./Statuses";
// import ClientLog from "./ClientLog";
// import Compliance from "./Compliance";

const ProjectDashboard = props => {
  const [project, setProject] = useState(false);

  useEffect(() => {
    triggerLoadProject();
  }, [props.match.params.id]);

  const triggerLoadProject = () => {
    getProject(props.match.params.id)
      .then(res => {
        setProject(res.data);
      })
      .catch(err => console.log(err));
  };

  return project ? (
    <Container fluid>
      <Row>
        <Col>
          <ProjectBar projectData={project} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            className="blueButton float-right mb-3 mt-0"
            onClick={() => props.history.goBack()}
          >
            BACK
          </Button>
        </Col>
      </Row>
      <Row>
        <Col sm="12" md="8">
          <ProjectData projectId={props.match.params.id} />
          <ProjectUpdates projectId={props.match.params.id} />
          <UserClaims projectId={props.match.params.id} />
        </Col>
        <Col sm="12" md="4">
          <ProjectAssignment
            projectId={props.match.params.id}
            companyId={project.companyId}
            triggerLoadProject={triggerLoadProject}
          />
          <ProjectLogs projectId={props.match.params.id} />
          <Claimants projectId={props.match.params.id} />

          {project.status === "new" ? (
            <Statuses projectData={project} projectId={props.match.params.id} />
          ) : null}

          {isMobileOnly ? (
            <Button
              className="blueButton float-right mb-3 mt-0"
              onClick={() => props.history.goBack()}
            >
              BACK
            </Button>
          ) : null}
        </Col>
      </Row>
      {/*<Row>*/}
      {/*  <Col>*/}
      {/*    <Claims projectId={props.match.params.id} />*/}
      {/*  </Col>*/}
      {/*</Row>*/}
    </Container>
  ) : null;
};

ProjectDashboard.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps)(ProjectDashboard);
