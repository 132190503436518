import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classes from "./Dashboard.module.css";
import { Button, Card, Form, Modal, Spinner } from "react-bootstrap";
import Select from "react-select";
import {
  getProject,
  updateProjectData,
} from "../../../../redux/actions/adminActions";
import { countryList } from "../../../../config/constants";
import { countryListReactSelect } from "../../../../utils/utils";
import { NotificationManager } from "react-notifications";

const ProjectData = props => {
  const [projectData, setProjectData] = useState(null);
  const [projectDataModal, setProjectDataModal] = useState(null);
  const [state, setState] = useState({
    disableUpdateProjectBtn: false,
    showUpdateModal: false,
    errors: "",
  });

  useEffect(() => {
    triggerGetProject();
  }, [props.projectId]);

  const triggerGetProject = () => {
    getProject(props.projectId)
      .then(res => {
        setProjectData(res.data);
      })
      .catch(err => console.log(err));
  };

  const handleChangeProjectData = (e, target) => {
    let name;
    let value;

    if (target === "country") {
      name = target;
      value = e.value;
    } else {
      name = e.target.name;
      value = e.target.value;
    }

    setProjectDataModal(prevState => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleUpdateProject = event => {
    event.preventDefault();
    event.stopPropagation();

    if (!projectDataModal.country || projectDataModal.country.length < 2) {
      alert("Choose country.");
      return false;
    }

    setState(prevState => {
      return {
        ...prevState,
        disableUpdateProjectBtn: true,
      };
    });

    updateProjectData(projectDataModal)
      .then(res => {
        handleCloseModal();
        if (res.data.success) {
          triggerGetProject();
          createNotification("updated");
        }
      })
      .catch(err => {
        handleCloseModal();
        createNotification("error");
        console.error(err);
      });
  };

  const handleShowModal = () => {
    setProjectDataModal(projectData);

    setState(prevState => {
      return {
        ...prevState,
        showUpdateModal: true,
      };
    });
  };

  const handleCloseModal = () => {
    setState(prevState => {
      return {
        ...prevState,
        disableUpdateProjectBtn: false,
        showUpdateModal: false,
      };
    });
  };

  const createNotification = (type, message = false) => {
    switch (type) {
      case "updated":
        NotificationManager.success(
          "You have successfully updated the project.",
          "Project Update",
          4000
        );
        break;
      case "error":
        NotificationManager.error(
          message || "Something went wrong!",
          "Error",
          5000
        );
        break;
      default:
    }
  };

  return (
    <>
      <Card className={classes.card}>
        <Card.Body className="p-0">
          <p
            className={[
              "p-3 m-0 border-bottom fontWeight500",
              classes.cardHeader,
            ].join(" ")}
          >
            Project Information
            <Button
              type="button"
              variant="link"
              className="smallGreenButton mt-1 px-2 text-white"
              onClick={handleShowModal}
            >
              + EDIT
            </Button>
          </p>

          {projectData ? (
            <>
              <p className="font-weight-bold mt-3 px-3">Project Name:</p>
              <p className="px-4">{projectData.projectName}</p>
              <p className="font-weight-bold mt-3 px-3">Project URL:</p>
              <p className="px-4">{projectData.projectURL}</p>
              <p className="font-weight-bold mt-3 px-3">Project Description:</p>
              <p className="px-4">{projectData.projectDescription}</p>
              <p className="font-weight-bold px-3">Project Scam Description:</p>
              <p className="px-4">{projectData.scamDescription}</p>
              <p className="font-weight-bold px-3">Country:</p>
              <p className="px-4">{countryList[projectData.country]}</p>
              <p className="font-weight-bold px-3">Comment:</p>
              <p className="px-4">{projectData.comment}</p>
            </>
          ) : null}
        </Card.Body>
      </Card>

      {projectDataModal ? (
        <Modal show={state.showUpdateModal} onHide={handleCloseModal} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Update Project</Modal.Title>
          </Modal.Header>

          <Form onSubmit={handleUpdateProject}>
            <Modal.Body>
              <Form.Group>
                <Form.Label className="font-weight-bold">
                  Project Name
                </Form.Label>
                <Form.Control
                  type="text"
                  name="projectName"
                  required
                  onChange={e => handleChangeProjectData(e, "")}
                  defaultValue={projectDataModal.projectName}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label className="font-weight-bold">
                  Project URL
                </Form.Label>
                <Form.Control
                  type="text"
                  name="projectURL"
                  required
                  onChange={e => handleChangeProjectData(e, "")}
                  defaultValue={projectDataModal.projectURL}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label className="font-weight-bold">
                  Project Description
                </Form.Label>
                <Form.Control
                  as="textarea"
                  maxLength="4000"
                  rows="5"
                  name="projectDescription"
                  placeholder="Describe how the Project was presented to the public."
                  required
                  onChange={e => handleChangeProjectData(e, "")}
                  defaultValue={projectDataModal.projectDescription}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label className="font-weight-bold">
                  Scam Description
                </Form.Label>
                <Form.Control
                  as="textarea"
                  maxLength="4000"
                  rows="5"
                  name="scamDescription"
                  placeholder="Describe, in your own words, how the fraud happens."
                  required
                  onChange={e => handleChangeProjectData(e, "")}
                  defaultValue={projectDataModal.scamDescription}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label className="font-weight-bold">
                  Country of project origin
                </Form.Label>
                <Select
                  name="country"
                  required
                  onChange={e => handleChangeProjectData(e, "country")}
                  options={countryListReactSelect()}
                  value={{
                    label: countryList[projectDataModal.country],
                    value: projectDataModal.country,
                  }}
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer className="border-0 justify-content-between">
              <Button
                variant="secondary"
                className="greyButton"
                onClick={handleCloseModal}
              >
                CANCEL
              </Button>

              <Button
                variant="primary"
                type="submit"
                className="blueButton"
                disabled={state.disableUpdateProjectBtn}
              >
                {state.disableUpdateProjectBtn ? (
                  <>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    SUBMIT
                  </>
                ) : (
                  "SUBMIT"
                )}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      ) : null}
    </>
  );
};

ProjectData.propTypes = {
  auth: PropTypes.object.isRequired,
  updateProjectData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps, { updateProjectData })(ProjectData);
