import React from "react";
import { getCryptocurrenciesSelectListOptions } from "../../utils/utils";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { TiDelete } from "react-icons/ti";
import { isMobileOnly } from "react-device-detect";

const Addresses = props => {
  let { addresses } = props;

  const columns = [
    {
      dataField: "cryptocurrency",
      text: "Cryptocurrency",
      headerStyle: (column, colIndex) => {
        return { width: "178px" };
      },
    },
    {
      dataField: "myAddress",
      text: "My Crypto Address",
      classes: "breakWord",
    },
    {
      dataField: "recipientAddress",
      text: "Recipient Crypto Address",
      classes: "breakWord",
    },
    {
      dataField: "amount",
      text: "Crypto Amount Sent",
      classes: "text-right",
      headerStyle: (column, colIndex) => {
        return { textAlign: "right" };
      },
    },
  ];

  const style = {
    borderBottom: "1px solid var(--color-blue2)",
    paddingTop: "10px",
    paddingBottom: "20px",
    marginBottom: "10px",
  };
  const delBtnStyle = {
    width: "30px",
    height: "30px",
    color: "var(--color-red4)",
    cursor: "pointer",
  };
  if (isMobileOnly) {
    delBtnStyle["display"] = "block";
    delBtnStyle["marginTop"] = "7px";
    delBtnStyle["marginLeft"] = "auto";
    delBtnStyle["marginRight"] = "auto";
  }

  columns.push({
    dataField: "action",
    text: "",
    sort: false,
    headerStyle: (column, colIndex) => {
      return { width: "60px" };
    },
  });

  let addressesTable = [];
  if (addresses.length > 0) {
    addresses.map((keyName, keyIndex) => {
      let id = addresses[keyIndex].id;
      let cryptocurrency = addresses[keyIndex].cryptocurrency;
      let myAddress = addresses[keyIndex].myAddress;
      let recipientAddress = addresses[keyIndex].recipientAddress;
      let amount = addresses[keyIndex].amount;
      let action;

      // Some data formatting before displaying
      cryptocurrency = (
        <Form.Control
          as="select"
          name="cryptocurrency"
          required
          onChange={e => props.changeAddress(e, keyIndex)}
          defaultValue={cryptocurrency || ""}
        >
          <option value="">Cryptocurrency</option>
          {getCryptocurrenciesSelectListOptions()}
        </Form.Control>
      );

      myAddress = (
        <Form.Control
          type="text"
          name="myAddress"
          placeholder="Enter YOUR address"
          required
          onChange={e => props.changeAddress(e, keyIndex)}
          defaultValue={myAddress || ""}
        />
      );

      recipientAddress = (
        <Form.Control
          type="text"
          name="recipientAddress"
          placeholder="Enter SCAMMER address"
          required
          onChange={e => props.changeAddress(e, keyIndex)}
          defaultValue={recipientAddress || ""}
        />
      );

      amount = (
        <Form.Control
          type="number"
          name="amount"
          step="any"
          min="0"
          placeholder="Enter crypto amount"
          required
          onChange={e => props.changeAddress(e, keyIndex)}
          defaultValue={amount || ""}
        />
      );

      action = (
        // <Button
        //   onClick={() => props.removeAddress(id)}
        //   className="orangeButton orangeButtonSmaller py-0 m-0 mt-2"
        // >
        <TiDelete style={delBtnStyle} onClick={() => props.removeAddress(id)} />
        // </Button>
      );

      return addressesTable.push({
        id,
        cryptocurrency,
        myAddress,
        recipientAddress,
        amount,
        action,
      });
    });
  }

  addressesTable.push({
    id: "add",
    cryptocurrency: (
      <Button
        onClick={() => props.addNewAddress()}
        className="blueButton float-left m-0 w-auto"
      >
        ADD ADDRESS
      </Button>
    ),
    myAddress: "",
    recipientAddress: "",
    amount: "",
    action: "",
  });

  return !isMobileOnly ? (
    <BootstrapTable
      bootstrap4
      keyField="id"
      data={addressesTable}
      columns={columns}
      bordered={false}
      headerClasses="tableHeaderClass"
    />
  ) : (
    <Container className="p-0">
      <Row className="no-gutters">
        {addressesTable.map((val, idx) => (
          <Col key={idx} style={style} lg={12}>
            {addressesTable[idx].id === "add" ? (
              addressesTable[idx].cryptocurrency
            ) : (
              <>
                <div className="pb-1">
                  <strong>Cryptocurrency:&nbsp;&nbsp;</strong>
                  <br />
                  {addressesTable[idx].cryptocurrency}
                </div>
                <div className="pb-1">
                  <strong>My Crypto Address:</strong>
                  <br />
                  {addressesTable[idx].myAddress}
                </div>
                <div className="pb-1">
                  <strong>Recipient Crypto Address:</strong>
                  <br />
                  {addressesTable[idx].recipientAddress}
                </div>
                <div>
                  <strong>Crypto Amount:&nbsp;&nbsp;</strong>
                  <br />
                  {addressesTable[idx].amount}
                </div>
                <div>{addressesTable[idx].action}</div>
              </>
            )}
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Addresses;
