import axios from "axios";
import store from "../../redux/store";
import { GET_ERRORS, GET_SUCCESS, SET_MENU_COUNTERS } from "./types";
import { API_URL } from "../../config/config";

// Get Admins
export const getAdmins = () =>
  axios.get(API_URL + "/api/admin/users/getAdmins");

// Get Lawyer Company
export const getCompany = id =>
  axios.get(API_URL + "/api/admin/legals/company/" + id);

// Get all companies
export const getCompanies = (status, limit) =>
  axios.get(API_URL + "/api/admin/legals/companies/" + status + "/" + limit);

// Add Lawyer Company
export const addCompany = lawyerData =>
  axios.post(API_URL + "/api/admin/legals/addCompany", lawyerData);

// Edit Lawyer Company
export const editCompany = lawyerData =>
  axios.post(API_URL + "/api/admin/legals/editCompany", lawyerData);

// Get all lawyers
export const getLawyers = (status, limit) =>
  axios.get(API_URL + "/api/admin/legals/list/" + status + "/" + limit);

// Edit lawyer
export const editLawyer = lawyerData =>
  axios.post(API_URL + "/api/admin/legals/editLawyer", lawyerData);

// Get user
export const getUser = id =>
  axios.get(`${API_URL}/api/admin/users/${id}/profile`);

// Get all users
export const getUsers = (status, limit) =>
  axios.get(API_URL + "/api/admin/users/list/" + status + "/" + limit);

// Get user logs
export const getUserLogs = id =>
  axios.get(`${API_URL}/api/admin/users/${id}/logs`);

// Get Client Application Data
export const getClientApplicationData = id =>
  axios.get(API_URL + "/api/admin/users/getClientApplicationData/" + id);

// Get sidebar counts
export const getSidebarCounts = () =>
  axios.get(API_URL + "/api/admin/claims/getSidebarCounts/");

// Set menu counters
export const setMenuCounters = counters => ({
  type: SET_MENU_COUNTERS,
  payload: counters,
});

// Add user
export const addUser = userData => dispatch => {
  axios
    .post(API_URL + "/api/admin/users/register", userData)
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Edit user
export const editUser = userData =>
  axios.post(API_URL + "/api/admin/users/editUser", userData);

// Add user log
export const addLog = logData =>
  axios.post(API_URL + "/api/admin/users/addLog", logData);

// Get Projects
export const getProjects = (userId, status) =>
  axios.get(`${API_URL}/api/admin/projects/getProjects/${userId}/${status}`);

// Get Project data
export const getProject = id =>
  axios.get(`${API_URL}/api/admin/projects/${id}/getProject`);

// Add project
export const addProject = data =>
  axios.post(API_URL + "/api/admin/projects/addProject", data);

// Update project
export const updateProjectData = data =>
  axios.post(API_URL + "/api/admin/projects/updateProjectData", data);

// Get Project reservations
export const getProjectReservations = id =>
  axios.get(`${API_URL}/api/admin/projects/${id}/getProjectReservations`);

// Update project
export const updateProjectAssignment = (projectId, companyId) =>
  axios.post(API_URL + "/api/admin/projects/updateProjectAssignment", {
    projectId,
    companyId,
  });

// Process project
export const processProject = projectData =>
  axios.post(API_URL + "/api/admin/projects/processProject", projectData);

// Get Project logs
export const getProjectLogs = id =>
  axios.get(`${API_URL}/api/admin/projects/${id}/getProjectLogs`);

// Add project log
export const addProjectLog = logData =>
  axios.post(API_URL + "/api/admin/projects/addProjectLog", logData);

// Get Client Claimants
export const getClaimants = projectId =>
  axios.get(`${API_URL}/api/admin/claims/${projectId}/getClaimants`);

// Get Project updates
export const getProjectUpdates = id =>
  axios.get(`${API_URL}/api/projects/${id}/getProjectUpdates`);

// Add project update
export const addProjectUpdate = update =>
  axios.post(API_URL + "/api/admin/projects/addProjectUpdate", update);

// Get claims
export const getClaims = (userId, projectId, status) =>
  axios.get(
    `${API_URL}/api/admin/claims/${userId}/${projectId}/${status}/getClaims`
  );

// Get claim
export const getClaim = claimId =>
  axios.get(`${API_URL}/api/admin/claims/${claimId}/getClaim`);

// Update claim
export const updateClaim = claim =>
  axios.post(API_URL + "/api/admin/claims/updateClaim", claim);

// Get Claim logs
export const getClaimLogs = id =>
  axios.get(API_URL + "/api/admin/claims/getClaimLogs/" + id);

// Resend verification email
export const resendVerificationEmail = (userId, userEmail) =>
  axios.post(API_URL + "/api/users/resendVerificationEmail", {
    userId,
    userEmail,
  });
