import React, { useEffect, useState } from "react";
import { getClaimLogs } from "../../redux/actions/adminActions";
import BootstrapTable from "react-bootstrap-table-next";
import { Button, Modal } from "react-bootstrap";
import paginationFactory from "react-bootstrap-table2-paginator";
import moment from "moment";

const ClaimLogs = props => {
  const [logs, setLogs] = useState({});

  useEffect(() => {
    getClaimLogs(props.claimId)
      .then(res => {
        setLogs(res.data);
      })
      .catch(err => console.log(err));
  }, []);

  const columnsClaimLog = [
    {
      dataField: "timestamp",
      text: "DATE",
      sort: true,
      classes: "breakWord",
      headerStyle: (column, colIndex) => {
        return { width: "150px" };
      },
    },
    {
      dataField: "event",
      text: "EVENT",
      classes: "breakWord",
    },
    {
      dataField: "actionedBy",
      text: "ACTIONED BY",
      sort: true,
      classes: "breakWord",
    },
  ];

  const defaultSorted = [
    {
      dataField: "timestamp",
      order: "desc",
    },
  ];

  const pagination = paginationFactory({
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
    ],
  });

  let dataClaimLog = [];
  if (!!logs) {
    for (let i = 0; i < logs.length; i++) {
      if (Number.isInteger(logs[i].timestamp)) {
        logs[i].timestamp = moment(logs[i].timestamp * 1000)
          .format("YYYY/MM/DD HH:mm:ss")
          .toString();
      }

      dataClaimLog.push(logs[i]);
    }
  }

  return (
    <Modal show={true} onHide={props.handleCloseModal} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Claim Logs</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <BootstrapTable
          bootstrap4
          bordered={false}
          keyField="_id"
          data={dataClaimLog}
          columns={columnsClaimLog}
          defaultSorted={defaultSorted}
          hover={true}
          pagination={pagination}
          // classes={classes.table}
          // headerClasses={classes.tableHeaderClass}
        />
      </Modal.Body>

      <Modal.Footer className="border-0">
        <Button
          variant="secondary"
          className="greyButton"
          onClick={props.handleCloseModal}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ClaimLogs;
