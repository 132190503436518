import React from "react";
// import classes from "./Profile.module.css";
import { numberFormat } from "../../utils/utils";
import BootstrapTable from "react-bootstrap-table-next";
import { Button, Modal } from "react-bootstrap";

const ClaimAddresses = props => {
  const columns = [
    {
      dataField: "cryptocurrency",
      text: "CRYPTOCURRENCY",
      sort: true,
    },
    {
      dataField: "address",
      text: "ADDRESS",
      sort: true,
      classes: "breakWord",
    },
    {
      dataField: "amountLost",
      text: "AMOUNT",
      sort: true,
      classes: "text-right",
      headerStyle: (column, colIndex) => {
        return { textAlign: "right" };
      },
    },
    {
      dataField: "comment",
      text: "COMMENT",
      sort: true,
      classes: "breakWord",
    },
  ];

  let data = [];
  if (!!props.claimAddresses) {
    for (let i = 0; i < props.claimAddresses.length; i++) {
      props.claimAddresses[i].amountLost = numberFormat(
        props.claimAddresses[i].amount,
        2,
        ".",
        ","
      );

      data.push(props.claimAddresses[i]);
    }
  }

  return (
    <Modal show={true} onHide={props.handleCloseModal} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Claim Addresses</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <BootstrapTable
          bootstrap4
          bordered={false}
          keyField="id"
          data={data}
          columns={columns}
          hover={true}
        />
      </Modal.Body>

      <Modal.Footer className="border-0">
        <Button
          variant="secondary"
          className="greyButton"
          onClick={props.handleCloseModal}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ClaimAddresses;
