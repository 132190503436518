import React, { useEffect, useState } from "react";
import { countryList } from "../../../../config/constants";
import { countryListReactSelect } from "../../../../utils/utils";
import {
  getCompanies,
  getCompany,
  addCompany,
  editCompany,
} from "../../../../redux/actions/adminActions";
import {
  Button,
  Col,
  Container,
  Row,
  Modal,
  Form,
  Spinner,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import Select from "react-select";
import moment from "moment";
import { NotificationManager } from "react-notifications";

const CompanyList = props => {
  const [state, setState] = useState({
    companies: {},
    // showCompanyStatus: "",
    newCompany: {},
    selectedCompany: {},
    loadingCompanies: true,
    disableAddEditCompanyBtn: false,
    disableRegisterApiBtn: false,
    addCompany: false,
    editingCompany: false,
    errors: {},
  });

  useEffect(() => {
    triggerLoadCompanies();

    // const companyLoader = setInterval(triggerLoadCompanies, 30000);

    // Clean up setInterval
    // return () => {
    //   clearInterval(companyLoader);
    // };
  }, [props.match.params.status]);

  const triggerLoadCompanies = () => {
    setState(prevState => {
      return {
        ...prevState,
        loadingCompanies: true,
      };
    });
    getCompanies("all", 0)
      .then(res => {
        if (res.data.success === false) {
          createNotification("error", res.data.error);
          setState(prevState => {
            return {
              ...prevState,
              loadingCompanies: false,
            };
          });
        } else
          setState(prevState => {
            return {
              ...prevState,
              companies: res.data.companies,
              loadingCompanies: false,
            };
          });
      })
      .catch(err => console.log(err));
  };

  const triggerLoadCompany = companyId => {
    getCompany(companyId)
      .then(res => {
        if (res.data.success === false) {
          createNotification("error", res.data.error);
        } else
          setState(prevState => {
            return {
              ...prevState,
              editingCompany: true,
              selectedCompany: res.data.company,
            };
          });
      })
      .catch(err => console.log(err));
  };

  const handleShowAddCompany = () => {
    setState(prevState => {
      return {
        ...prevState,
        addCompany: true,
      };
    });
  };

  const handleCloseModal = () => {
    setState(prevState => {
      return {
        ...prevState,
        addCompany: false,
        editingCompany: false,
        disableAddEditCompanyBtn: false,
      };
    });
  };

  const handleChangeCompany = (e, target, type) => {
    let name;
    let value;

    if (target === "country") {
      name = target;
      value = e.value;
    } else {
      name = e.target.name;
      value = e.target.value;
    }

    if (type === "add") {
      const { newCompany } = state;
      newCompany[name] = value;
      setState(prevState => {
        return {
          ...prevState,
          newCompany,
        };
      });
    }

    if (type === "edit") {
      const { selectedCompany } = state;
      selectedCompany[name] = value;
      setState(prevState => {
        return {
          ...prevState,
          selectedCompany,
        };
      });
    }
  };

  const handleSubmit = (event, type) => {
    event.preventDefault();
    event.stopPropagation();

    setState(prevState => {
      return {
        ...prevState,
        disableAddEditCompanyBtn: true,
      };
    });

    if (type === "add") {
      addCompany(state.newCompany)
        .then(res => {
          if (res.data.success) {
            createNotification("successAdded");
            triggerLoadCompanies();
            handleCloseModal();
          } else {
            createNotification("error", res.data.error);
          }
        })
        .catch(err => console.log(err));
    }
    if (type === "edit") {
      editCompany(state.selectedCompany)
        .then(res => {
          if (res.data.success) {
            createNotification("successUpdated");
            triggerLoadCompanies();
            handleCloseModal();
          } else {
            createNotification("error", res.data.error);
          }
        })
        .catch(err => console.log(err));
    }
  };

  const createNotification = (type, message = false) => {
    switch (type) {
      case "successAdded":
        NotificationManager.success(
          "You have successfully added the company.",
          "Added Company",
          5000
        );
        break;
      case "successUpdated":
        NotificationManager.success(
          "You have successfully updated the company.",
          "Updated Company",
          5000
        );
        break;
      case "error":
        NotificationManager.error(
          message || "Something went wrong!",
          "Error",
          5000
        );
        break;
      default:
    }
  };

  const {
    companies,
    loadingCompanies,
    selectedCompany,
    disableAddEditCompanyBtn,
  } = state;

  const columns = [
    {
      dataField: "name",
      text: "Company Name",
      sort: true,
      classes: "breakWord",
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      classes: "breakWord",
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "authorizedPerson",
      text: "Authorized Person",
      sort: true,
      classes: "breakWord",
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "companyCountry",
      text: "Country",
      sort: true,
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "timestamp",
      text: "Time of Registration",
      sort: true,
      // filter: textFilter({placeholder: 'Search...'})
    },
  ];

  const defaultSorted = [
    {
      dataField: "timestamp",
      order: "asc",
    },
  ];

  const rowEvents = {
    onDoubleClick: (e, row, rowIndex) => {
      triggerLoadCompany(row._id);
    },
  };

  let data = [];
  if (Object.keys(companies).length > 0) {
    for (let i = 0; i < companies.length; i++) {
      // Some data formatting before displaying
      companies[i].companyCountry = countryList[companies[i].country];

      if (Number.isInteger(companies[i].timestamp)) {
        companies[i].timestamp = moment(companies[i].timestamp * 1000)
          .format("YYYY/MM/DD HH:mm:ss")
          .toString();
      }

      data.push(companies[i]);
    }
  }

  const rowStyle = (row, rowIndex) => {
    let style = {};

    if (row.status === "rejected") {
      style = { color: "#f00" };
    }

    return style;
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <h1 className="pt-3 px-3">
              Companies
              {loadingCompanies ? (
                <Spinner animation="border" size="sm" className="float-right" />
              ) : null}
            </h1>
            <Button
              className="blueButton float-right"
              style={{ marginTop: 0, marginBottom: "10px", width: "auto" }}
              onClick={handleShowAddCompany}
            >
              ADD COMPANY
            </Button>
          </Col>
        </Row>

        <Row>
          <Col>
            <BootstrapTable
              bootstrap4
              keyField="_id"
              data={data}
              columns={columns}
              defaultSorted={defaultSorted}
              hover={true}
              filter={filterFactory()}
              rowEvents={rowEvents}
              rowStyle={rowStyle}
              bordered={false}
              headerClasses="tableHeaderClass"
            />

            {state.addCompany ? (
              <Modal show={true} onHide={handleCloseModal} size="lg">
                <Modal.Header closeButton>
                  <Modal.Title>Add company</Modal.Title>
                </Modal.Header>

                <Form onSubmit={e => handleSubmit(e, "add")}>
                  <Modal.Body>
                    <Form.Row>
                      <Form.Group as={Col} md="6" xs="6">
                        <Form.Label>Company number</Form.Label>
                        <Form.Control
                          type="text"
                          name="number"
                          placeholder="Enter company number"
                          onChange={e => handleChangeCompany(e, "", "add")}
                        />
                      </Form.Group>

                      <Form.Group as={Col} md="6" xs="12">
                        <Form.Label>Company name</Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder="Enter Company name"
                          onChange={e => handleChangeCompany(e, "", "add")}
                          required
                        />
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group as={Col} md="6" xs="12">
                        <Form.Label>Authorized person</Form.Label>
                        <Form.Control
                          type="text"
                          name="authorizedPerson"
                          placeholder="Enter authorized person name"
                          onChange={e => handleChangeCompany(e, "", "add")}
                        />
                      </Form.Group>

                      <Form.Group as={Col} md="6" xs="12">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          placeholder="Enter email"
                          onChange={e => handleChangeCompany(e, "", "add")}
                        />
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group as={Col} md="6" xs="12">
                        <Form.Label>Phone number</Form.Label>
                        <Form.Control
                          type="text"
                          name="phoneNumber"
                          placeholder="Enter phone number"
                          onChange={e => handleChangeCompany(e, "", "add")}
                        />
                      </Form.Group>
                      <Form.Group as={Col} md="6" xs="12">
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                          type="text"
                          name="address"
                          placeholder="Enter company address"
                          onChange={e => handleChangeCompany(e, "", "add")}
                        />
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group as={Col} md="6" xs="12">
                        <Form.Label>Country</Form.Label>
                        <Select
                          name="country"
                          onChange={e =>
                            handleChangeCompany(e, "country", "add")
                          }
                          options={countryListReactSelect()}
                          defaultValue={{
                            label: "Choose country...",
                            value: "",
                          }}
                        />
                      </Form.Group>
                      <Form.Group as={Col} md="6" xs="12">
                        <Form.Label>Status</Form.Label>
                        <Form.Control
                          as="select"
                          name="status"
                          required
                          onChange={e => handleChangeCompany(e, "", "add")}
                        >
                          <option value="">Choose Status</option>
                          <option value="approved">Approved</option>
                          <option value="rejected">Rejected</option>
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Label>Comment</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="comment"
                        placeholder="Enter a comment"
                        maxLength="4000"
                        rows="5"
                        onChange={e => handleChangeCompany(e, "", "add")}
                      />
                    </Form.Row>
                  </Modal.Body>
                  <Modal.Footer className="border-0 justify-content-between">
                    <Button
                      variant="secondary"
                      className="greyButton"
                      onClick={handleCloseModal}
                    >
                      Cancel
                    </Button>

                    <Button
                      variant="primary"
                      type="submit"
                      className="darkBlueButton"
                      style={{ fontWeight: "normal" }}
                      disabled={disableAddEditCompanyBtn}
                    >
                      {disableAddEditCompanyBtn ? (
                        <>
                          <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          Add
                        </>
                      ) : (
                        "Add"
                      )}
                    </Button>
                  </Modal.Footer>
                </Form>
              </Modal>
            ) : null}

            {state.editingCompany ? (
              <Modal show={true} onHide={handleCloseModal} size="lg">
                <Modal.Header closeButton>
                  <Modal.Title>Edit company</Modal.Title>
                </Modal.Header>

                <Form onSubmit={e => handleSubmit(e, "edit")}>
                  <Modal.Body>
                    <Form.Row>
                      <Form.Group as={Col} md="6" xs="6">
                        <Form.Label>Company number</Form.Label>
                        <Form.Control
                          type="text"
                          name="number"
                          placeholder="Enter company number"
                          onChange={e => handleChangeCompany(e, "", "edit")}
                          defaultValue={selectedCompany.number}
                        />
                      </Form.Group>

                      <Form.Group as={Col} md="6" xs="12">
                        <Form.Label>Company name</Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder="Enter Company name"
                          onChange={e => handleChangeCompany(e, "", "edit")}
                          defaultValue={selectedCompany.name}
                          required
                        />
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group as={Col} md="6" xs="12">
                        <Form.Label>Authorized person</Form.Label>
                        <Form.Control
                          type="text"
                          name="authorizedPerson"
                          placeholder="Enter authorized person name"
                          onChange={e => handleChangeCompany(e, "", "edit")}
                          defaultValue={selectedCompany.authorizedPerson}
                        />
                      </Form.Group>

                      <Form.Group as={Col} md="6" xs="12">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          placeholder="Enter email"
                          onChange={e => handleChangeCompany(e, "", "edit")}
                          defaultValue={selectedCompany.email}
                        />
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group as={Col} md="6" xs="12">
                        <Form.Label>Phone number</Form.Label>
                        <Form.Control
                          type="text"
                          name="phoneNumber"
                          placeholder="Enter phone number"
                          onChange={e => handleChangeCompany(e, "", "edit")}
                          defaultValue={selectedCompany.phoneNumber}
                        />
                      </Form.Group>
                      <Form.Group as={Col} md="6" xs="12">
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                          type="text"
                          name="address"
                          placeholder="Enter company address"
                          onChange={e => handleChangeCompany(e, "", "edit")}
                          defaultValue={selectedCompany.address}
                        />
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group as={Col} md="6" xs="12">
                        <Form.Label>Country</Form.Label>
                        <Select
                          name="country"
                          onChange={e =>
                            handleChangeCompany(e, "country", "edit")
                          }
                          options={countryListReactSelect()}
                          value={{
                            label: countryList[selectedCompany.country],
                            value: selectedCompany.country,
                          }}
                        />
                      </Form.Group>
                      <Form.Group as={Col} md="6" xs="12">
                        <Form.Label>Status</Form.Label>
                        <Form.Control
                          as="select"
                          name="status"
                          required
                          onChange={e => handleChangeCompany(e, "", "edit")}
                          value={selectedCompany.status || ""}
                        >
                          <option value="">Choose Status</option>
                          <option value="approved">Approved</option>
                          <option value="rejected">Rejected</option>
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Label>Comment</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="comment"
                        placeholder="Enter a comment"
                        maxLength="4000"
                        rows="5"
                        onChange={e => handleChangeCompany(e, "", "edit")}
                        value={selectedCompany.comment || ""}
                      />
                    </Form.Row>
                  </Modal.Body>
                  <Modal.Footer className="border-0 justify-content-between">
                    <Button
                      variant="secondary"
                      className="greyButton"
                      onClick={handleCloseModal}
                    >
                      Cancel
                    </Button>

                    <Button
                      variant="primary"
                      type="submit"
                      className="darkBlueButton"
                      style={{ fontWeight: "normal" }}
                      disabled={disableAddEditCompanyBtn}
                    >
                      {disableAddEditCompanyBtn ? (
                        <>
                          <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          Submit
                        </>
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </Modal.Footer>
                </Form>
              </Modal>
            ) : null}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CompanyList;
