import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Card, Container, Row, Col, Button } from "react-bootstrap";
import classes from "./Dashboard.module.css";
import { getClaimants } from "../../../../redux/actions/adminActions";
import { FaUserCircle } from "react-icons/fa";

const Claimants = props => {
  const [claimants, setClaimants] = useState([]);

  useEffect(() => {
    getClaimants(props.projectId)
      .then(res => {
        setClaimants(res.data);
      })
      .catch(err => console.error(err));
  }, [props.userId]);

  return (
    <Card className={classes.card}>
      <Card.Body className="p-0">
        <p
          className={[
            "p-3 m-0 border-bottom fontWeight500",
            classes.cardHeader,
          ].join(" ")}
        >
          Claimants
        </p>
        <Container className="py-2">
          {claimants.map(claimant => (
            <Row key={claimant._id} className="py-1">
              <Col lg="9" className="pt-1">
                {claimant.name} {claimant.surname}
              </Col>
              <Col lg="3">
                <Button
                  variant="link"
                  as={Link}
                  to={`/user/profile/${claimant._id}`}
                  className="p-0"
                >
                  <FaUserCircle />
                </Button>
              </Col>
            </Row>
          ))}
        </Container>
      </Card.Body>
    </Card>
  );
};

Claimants.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps)(Claimants);
