import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { userRoles, countryList } from "../../../../config/constants";
import {
  getUserRolesSelectListOptions,
  getUserStatusSelectListOptions,
  countryListReactSelect,
  encryptData,
} from "../../../../utils/utils";
import {
  getUsers,
  getUser,
  editUser,
  resendVerificationEmail,
} from "../../../../redux/actions/adminActions";
import {
  Button,
  Col,
  Container,
  Row,
  Modal,
  Form,
  Spinner,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import Select from "react-select";
import moment from "moment";
import { NotificationManager } from "react-notifications";

const UserList = props => {
  const [state, setState] = useState({
    users: {},
    selectedUser: {},
    loadingUsers: true,
    disableEditUserBtn: false,
    disableRegisterApiBtn: false,
    disableResendVerifEmailBtn: false,
    editingUser: false,
    validated: false,
  });

  const statusTitles = {
    all: "All Users",
    new: "New Users",
    approved: "Approved Users",
    pending: "Pending Users",
    rejected: "Rejected Users",
  };

  useEffect(() => {
    triggerLoadUsers();

    const userLoader = setInterval(triggerLoadUsers, 30000);

    // Clean up setInterval
    return () => {
      clearInterval(userLoader);
    };
  }, [props.match.params.status]);

  const triggerLoadUsers = () => {
    setState(prevState => {
      return {
        ...prevState,
        loadingUsers: true,
      };
    });
    getUsers(props.match.params.status, 0)
      .then(res => {
        if (res.data.success === false) {
          setState(prevState => {
            return {
              ...prevState,
              loadingUsers: false,
            };
          });
          createNotification("error", res.data.error);
        } else
          setState(prevState => {
            return {
              ...prevState,
              users: res.data.users,
              loadingUsers: false,
            };
          });
      })
      .catch(err => console.log(err));
  };

  const triggerLoadUser = userId => {
    getUser(userId)
      .then(res => {
        if (res.data.success === false) {
          setState(prevState => {
            return {
              ...prevState,
              editingUser: false,
            };
          });
          createNotification("error", res.data.error);
        } else
          setState(prevState => {
            return {
              ...prevState,
              editingUser: true,
              selectedUser: res.data.user,
            };
          });
      })
      .catch(err => console.log(err));
  };

  const handleCloseModal = () => {
    setState(prevState => {
      return {
        ...prevState,
        editingUser: false,
        disableEditUserBtn: false,
        disableResendVerifEmailBtn: false,
      };
    });
  };

  // const handleUserStatusChange = e => {
  //   const userStatus = e.target.value;
  //   setState(prevState => {
  //     return {
  //       ...prevState,
  //       showUserStatus: userStatus,
  //       loadingUsers: true
  //     };
  //   });
  //
  //   getUsers(userStatus, 0)
  //     .then(res => {
  //       setState(prevState => {
  //         return {
  //           ...prevState,
  //           users: res.data.users,
  //           loadingUsers: false
  //         };
  //       });
  //     })
  //     .catch(err => console.log(err));
  // };

  const handleChangeProfile = (e, target) => {
    let name;
    let value;

    if (
      target === "country" ||
      target === "nationality" ||
      target === "birthDay" ||
      target === "birthMonth" ||
      target === "birthYear"
    ) {
      name = target;
      value = e.value;
    } else {
      name = e.target.name;
      value = e.target.value;
    }

    if (name === "emailVerified") {
      value = e.target.checked;
    }

    const { selectedUser } = state;
    selectedUser[name] = value;
    setState(prevState => {
      return {
        ...prevState,
        selectedUser,
      };
    });
  };

  const handleSubmit = event => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    const { selectedUser } = state;

    if (
      (userRoles[props.auth.user.role] < userRoles["SYSOP"] &&
        selectedUser.role === "SYSOP") ||
      userRoles[selectedUser.role] >= userRoles[props.auth.user.role]
    ) {
      createNotification("error", "You cannot set this user role!");
      setState(prevState => {
        return {
          ...prevState,
          disableEditUserBtn: false,
        };
      });
      return false;
    }

    if (
      (selectedUser.password || selectedUser.password2) &&
      selectedUser.password !== selectedUser.password2
    ) {
      createNotification("error", "Passwords must match!");
      setState(prevState => {
        return {
          ...prevState,
          disableEditUserBtn: false,
        };
      });
      return false;
    }

    setState(prevState => {
      return {
        ...prevState,
        disableEditUserBtn: true,
      };
    });

    if (form.checkValidity()) {
      const currentUser = { ...selectedUser };

      if (currentUser.password)
        currentUser.password = encryptData(currentUser.password);
      if (currentUser.password2)
        currentUser.password2 = encryptData(currentUser.password2);

      editUser(currentUser)
        .then(res => {
          handleCloseModal();
          if (res.data.success) {
            createNotification("successUpdate");
            triggerLoadUsers();
          } else createNotification("error", res.data.error);

          setState(prevState => {
            return {
              ...prevState,
              disableEditUserBtn: false,
            };
          });
        })
        .catch(err => console.log(err));
    }
  };

  const handleResendVerificationEmail = (id, email) => {
    setState(prevState => {
      return { ...prevState, disableResendVerifEmailBtn: true };
    });

    resendVerificationEmail(id, email)
      .then(res => {
        if (res.data.success) {
          createNotification("successEmail");
          handleCloseModal();
        } else {
          createNotification("error", res.data.error);
        }
      })
      .catch(err => console.log(err));
  };

  const createNotification = (type, message = false) => {
    switch (type) {
      case "successUpdate":
        NotificationManager.success(
          "You have successfully updated the client.",
          "Client Update",
          5000
        );
        break;
      case "successEmail":
        NotificationManager.success(
          "You have successfully sent verification email to the client.",
          "Email Sent",
          5000
        );
        break;
      case "error":
        NotificationManager.error(
          message || "Something went wrong!",
          "Error",
          5000
        );
        break;
      default:
    }
  };

  const {
    validated,
    users,
    loadingUsers,
    selectedUser,
    disableEditUserBtn,
  } = state;

  const columns = [
    {
      dataField: "name",
      text: "First Name",
      sort: true,
      classes: "breakWord",
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "surname",
      text: "Last Name",
      sort: true,
      classes: "breakWord",
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      classes: "breakWord",
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "userCountry",
      text: "Country",
      sort: true,
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "userRole",
      text: "Role",
      sort: true,
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "timestamp",
      text: "Time of Registration",
      sort: true,
    },
  ];

  const defaultSorted = [
    {
      dataField: "timestamp",
      order: "asc",
    },
  ];

  const rowEvents = {
    onDoubleClick: (e, row, rowIndex) => {
      triggerLoadUser(row._id);
    },
  };

  let data = [];
  if (Object.keys(users).length > 0) {
    for (let i = 0; i < users.length; i++) {
      // Some data formatting before displaying
      users[i].userCountry = countryList[users[i].country];

      users[i].userRole = users[i].role;

      if (Number.isInteger(users[i].timestamp)) {
        users[i].timestamp = moment(users[i].timestamp * 1000)
          .format("YYYY/MM/DD HH:mm:ss")
          .toString();
      }

      data.push(users[i]);
    }
  }

  const rowStyle = (row, rowIndex) => {
    let style = {};

    if (!row.emailVerified) {
      style = { color: "#f00" };
    }

    return style;
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <h1 className="pt-3 px-3">
              {statusTitles[props.match.params.status]}{" "}
              {loadingUsers ? (
                <Spinner animation="border" size="sm" className="float-right" />
              ) : null}
            </h1>
          </Col>
        </Row>

        <Row>
          <Col>
            <BootstrapTable
              bootstrap4
              keyField="_id"
              data={data}
              columns={columns}
              defaultSorted={defaultSorted}
              hover={true}
              filter={filterFactory()}
              rowEvents={rowEvents}
              rowStyle={rowStyle}
              bordered={false}
              headerClasses="tableHeaderClass"
            />

            {state.editingUser ? (
              <Modal show={true} onHide={handleCloseModal} size="lg">
                <Modal.Header closeButton>
                  <Modal.Title>Edit user</Modal.Title>
                </Modal.Header>

                <Form validated={validated} onSubmit={e => handleSubmit(e)}>
                  <Modal.Body>
                    <Form.Row>
                      {userRoles[props.auth.user.role] >= userRoles["ADMIN"] ? (
                        <Form.Group as={Col} md="6" xs="12">
                          <Form.Label>Role</Form.Label>
                          <Form.Control
                            as="select"
                            name="role"
                            required
                            onChange={e => handleChangeProfile(e, "")}
                            defaultValue={selectedUser.role}
                          >
                            <option value="">Choose user role</option>
                            {getUserRolesSelectListOptions(
                              userRoles[props.auth.user.role]
                            )}
                          </Form.Control>
                        </Form.Group>
                      ) : null}

                      <Form.Group as={Col} md="6" xs="12">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          placeholder="Enter email"
                          disabled
                          defaultValue={selectedUser.email}
                        />
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group as={Col} md="6" xs="12">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          type="password"
                          name="password"
                          placeholder="Enter password"
                          onChange={e => handleChangeProfile(e, "")}
                        />
                      </Form.Group>

                      <Form.Group as={Col} md="6" xs="12">
                        <Form.Label>Confirm password</Form.Label>
                        <Form.Control
                          type="password"
                          name="password2"
                          placeholder="Confirm password"
                          onChange={e => handleChangeProfile(e, "")}
                        />
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group as={Col} md="6" xs="6">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder="Enter your name"
                          onChange={e => handleChangeProfile(e, "")}
                          defaultValue={selectedUser.name}
                        />
                      </Form.Group>

                      <Form.Group as={Col} md="6" xs="12">
                        <Form.Label>Surname</Form.Label>
                        <Form.Control
                          type="text"
                          name="surname"
                          placeholder="Enter your surname"
                          onChange={e => handleChangeProfile(e, "")}
                          defaultValue={selectedUser.surname}
                        />
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      {/*<Col md="6" xs="12">*/}
                      {/*  <Form.Row>*/}
                      {/*    <Form.Group as={Col}>*/}
                      {/*      <Form.Label>Birth day</Form.Label>*/}
                      {/*      <Select*/}
                      {/*        name="birthDay"*/}
                      {/*        onChange={e => handleChangeProfile(e, "birthDay")}*/}
                      {/*        options={getBirthDaysReactSelect()}*/}
                      {/*        value={{*/}
                      {/*          label: selectedUser.birthDay || "",*/}
                      {/*          value: selectedUser.birthDay || "",*/}
                      {/*        }}*/}
                      {/*      />*/}
                      {/*    </Form.Group>*/}
                      {/*    <Form.Group as={Col}>*/}
                      {/*      <Form.Label>Birth month</Form.Label>*/}
                      {/*      <Select*/}
                      {/*        name="birthMonth"*/}
                      {/*        onChange={e =>*/}
                      {/*          handleChangeProfile(e, "birthMonth")*/}
                      {/*        }*/}
                      {/*        options={getBirthMonthsReactSelect()}*/}
                      {/*        value={{*/}
                      {/*          label: selectedUser.birthMonth || "",*/}
                      {/*          value: selectedUser.birthMonth || "",*/}
                      {/*        }}*/}
                      {/*      />*/}
                      {/*    </Form.Group>*/}
                      {/*    <Form.Group as={Col}>*/}
                      {/*      <Form.Label>Birth year</Form.Label>*/}
                      {/*      <Select*/}
                      {/*        name="birthYear"*/}
                      {/*        onChange={e =>*/}
                      {/*          handleChangeProfile(e, "birthYear")*/}
                      {/*        }*/}
                      {/*        options={getBirthYearsReactSelect()}*/}
                      {/*        value={{*/}
                      {/*          label: selectedUser.birthYear || "",*/}
                      {/*          value: selectedUser.birthYear || "",*/}
                      {/*        }}*/}
                      {/*      />*/}
                      {/*    </Form.Group>*/}
                      {/*  </Form.Row>*/}
                      {/*</Col>*/}
                      <Form.Group as={Col} md="6" xs="12">
                        <Form.Label>Mobile phone</Form.Label>
                        <Form.Control
                          type="text"
                          name="mobilePhone"
                          placeholder="Enter your phone number"
                          onChange={e => handleChangeProfile(e, "")}
                          defaultValue={selectedUser.mobilePhone}
                        />
                      </Form.Group>
                      <Form.Group as={Col} md="6" xs="12">
                        <Form.Label>Country</Form.Label>
                        <Select
                          name="country"
                          onChange={e => handleChangeProfile(e, "country")}
                          options={countryListReactSelect()}
                          value={{
                            label: countryList[selectedUser.country],
                            value: selectedUser.country,
                          }}
                        />
                      </Form.Group>
                    </Form.Row>

                    {userRoles[props.auth.user.role] > userRoles["ADMIN"] ||
                    (userRoles[props.auth.user.role] === userRoles["ADMIN"] &&
                      selectedUser.role !== "ADMIN") ? (
                      <Form.Row>
                        <Form.Group as={Col} md="6" xs="12">
                          <Form.Label>Status</Form.Label>
                          <Form.Control
                            as="select"
                            name="status"
                            required
                            onChange={e => handleChangeProfile(e, "")}
                            defaultValue={selectedUser.status}
                          >
                            <option value="">Choose user status</option>
                            {getUserStatusSelectListOptions()}
                          </Form.Control>
                        </Form.Group>
                        {selectedUser.status === "rejected" ? (
                          <Form.Group as={Col} md="6" xs="12">
                            <Form.Label>Comment</Form.Label>
                            <Form.Control
                              as="textarea"
                              maxLength="4000"
                              rows="5"
                              name="comment"
                              placeholder="Enter comment"
                              required
                              onChange={e => handleChangeProfile(e, "")}
                              defaultValue={selectedUser.comment || ""}
                            />
                          </Form.Group>
                        ) : null}
                      </Form.Row>
                    ) : null}

                    <Form.Group controlId="emailVerified">
                      <Form.Check
                        type="checkbox"
                        name="emailVerified"
                        label="Email verified"
                        checked={selectedUser.emailVerified}
                        onChange={e => handleChangeProfile(e, "")}
                      />
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer className="border-0 justify-content-between">
                    <Button
                      variant="secondary"
                      className="greyButton"
                      onClick={handleCloseModal}
                    >
                      Cancel
                    </Button>

                    <Button
                      variant="info"
                      className="darkBlueButton"
                      style={{ fontWeight: "normal" }}
                      onClick={() =>
                        handleResendVerificationEmail(
                          selectedUser._id,
                          selectedUser.email
                        )
                      }
                      disabled={state.disableResendVerifEmailBtn}
                    >
                      {state.disableResendVerifEmailBtn ? (
                        <>
                          <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          Please wait...
                        </>
                      ) : (
                        "Resend verif. email"
                      )}
                    </Button>

                    <Button
                      variant="primary"
                      type="submit"
                      className="darkBlueButton"
                      style={{ fontWeight: "normal" }}
                      disabled={disableEditUserBtn}
                    >
                      {disableEditUserBtn ? (
                        <>
                          <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          Submit
                        </>
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </Modal.Footer>
                </Form>
              </Modal>
            ) : null}
          </Col>
        </Row>
      </Container>
    </>
  );
};

UserList.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(UserList);
