import React, { useState, useEffect } from "react";
import classes from "./Dashboard.module.css";
import { Button, Card, Form, Spinner } from "react-bootstrap";
import Select from "react-select";
import {
  getProjectReservations,
  updateProjectAssignment,
} from "../../../../redux/actions/adminActions";
import { NotificationManager } from "react-notifications";

const ProjectAssignment = props => {
  const [projectAssigned, setProjectAssigned] = useState({
    value: "",
    label: "Choose company...",
  });
  const [projectReservations, setProjectReservations] = useState([]);
  const [projectCompanyId, setProjectCompanyId] = useState(props.companyId);
  const [state, setState] = useState({
    disableAssignmentBtn: false,
    errors: "",
  });

  useEffect(() => {
    getProjectReservations(props.projectId)
      .then(res => {
        const reservations = res.data;

        if (props.companyId) {
          reservations.map(value => {
            if (value.companyId === props.companyId) {
              setProjectAssigned({
                value: value.companyId,
                label: `${value.companyName} (${value.legalName})`,
              });
            }
          });
        }

        setProjectReservations(reservations);
      })
      .catch(err => console.log(err));
  }, [props.companyId]);

  const handleChangeProjectAssignment = e => {
    setProjectCompanyId(e.value);
    projectReservations.map(value => {
      if (value.companyId === e.value) {
        setProjectAssigned({
          value: value.companyId,
          label: `${value.companyName} (${value.legalName})`,
        });
      }
    });
  };

  const handleUpdateProjectAssignment = event => {
    event.preventDefault();
    event.stopPropagation();

    if (projectCompanyId) {
      setState(prevState => {
        return {
          ...prevState,
          disableAssignmentBtn: true,
        };
      });

      updateProjectAssignment(props.projectId, projectCompanyId)
        .then(res => {
          if (res.data.success) {
            createNotification("assigned");
            props.triggerLoadProject();
          } else createNotification("error", res.data.error);

          setState(prevState => {
            return {
              ...prevState,
              disableAssignmentBtn: false,
            };
          });
        })
        .catch(err => console.log(err));
    }
  };

  const createNotification = (type, message = false) => {
    switch (type) {
      case "assigned":
        NotificationManager.success(
          "You have successfully assigned the project.",
          "Project Assigned",
          4000
        );
        break;
      case "error":
        NotificationManager.error(
          message || "Something went wrong!",
          "Error",
          5000
        );
        break;
      default:
    }
  };

  return (
    <>
      <Card className={classes.card}>
        <Card.Body className="p-0">
          <p
            className={[
              "p-3 m-0 border-bottom fontWeight500",
              classes.cardHeader,
            ].join(" ")}
          >
            Project Assignment
          </p>
          <Form onSubmit={e => handleUpdateProjectAssignment(e)}>
            <div className="p-2">
              <Select
                name="companyId"
                onChange={e => handleChangeProjectAssignment(e)}
                options={projectReservations.map(value => ({
                  value: value.companyId,
                  label: `${value.companyName} (${value.legalName})`,
                }))}
                value={projectAssigned}
              />
            </div>

            {props.companyId !== projectCompanyId ? (
              <Button
                variant="primary"
                type="submit"
                className="darkBlueButton m-2"
                style={{ fontWeight: "normal" }}
                disabled={state.disableAssignmentBtn}
              >
                {state.disableAssignmentBtn ? (
                  <>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    Save
                  </>
                ) : (
                  "Save"
                )}
              </Button>
            ) : null}
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default ProjectAssignment;
