import React from "react";
import CryptoJS from "crypto-js";
import { encryptionKey } from "../config/config";
import {
  userStatuses,
  accountStatuses,
  countryList,
  userRoles,
  claimStatuses,
  claimTypes,
  cryptoCurrencies,
} from "../config/constants";

export const encryptData = data => {
  return CryptoJS.AES.encrypt(data, encryptionKey).toString();
};

export const decryptData = data => {
  return CryptoJS.AES.decrypt(data, encryptionKey).toString(CryptoJS.enc.Utf8);
};

export const encryptDataBase64 = data => {
  return CryptoJS.enc.Base64.stringify(
    CryptoJS.enc.Utf8.parse(data.toString())
  );
};

export const decryptDataBase64 = data => {
  return CryptoJS.enc.Base64.parse(data.toString()).toString(CryptoJS.enc.Utf8);
};

export const cutLongText = (text, limit) => {
  if (!text) return "";
  const lmt = parseInt(limit);
  return text.length > lmt ? text.substr(0, lmt) + "..." : text;
};

export const getUserStatusSelectListOptions = () =>
  Object.keys(userStatuses).map(userKey => (
    <option key={userKey} value={userKey}>
      {userStatuses[userKey]}
    </option>
  ));

export const getCountrySelectListOptions = () =>
  Object.keys(countryList).map(countryKey => (
    <option key={countryKey} value={countryKey}>
      {countryList[countryKey]}
    </option>
  ));

export const getClaimTypesSelectListOptions = () =>
  Object.keys(claimTypes).map(countryKey => (
    <option key={countryKey} value={countryKey}>
      {claimTypes[countryKey]}
    </option>
  ));

export const getClaimStatusesSelectListOptions = () =>
  Object.keys(claimStatuses).map(countryKey => (
    <option key={countryKey} value={countryKey}>
      {claimStatuses[countryKey]}
    </option>
  ));

export const getCryptocurrenciesSelectListOptions = () =>
  Object.keys(cryptoCurrencies).map(countryKey => (
    <option
      key={cryptoCurrencies[countryKey]}
      value={cryptoCurrencies[countryKey]}
    >
      {cryptoCurrencies[countryKey]}
    </option>
  ));

export const countryListReactSelect = () => {
  let countries = [];
  Object.keys(countryList).map((value, index) =>
    countries.push({ value: value, label: countryList[value] })
  );
  return countries;
};

export const companyListReactSelect = companies => {
  let countries = [];
  Object.keys(companies).map((value, index) =>
    countries.push({ value: value, label: companies[value].companyName })
  );
  return countries;
};

export const formatDateForDateFilter = date => {
  let d = new Date(date),
    month = "" + d.getMonth(),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const getBirthDaysReactSelect = () => {
  let days = [];
  for (let i = 1; i <= 31; i++) {
    days.push({ value: i, label: i });
  }
  return days;
};

export const getBirthMonthsReactSelect = () => {
  let months = [];
  for (let i = 1; i <= 12; i++) {
    months.push({ value: i, label: i });
  }
  return months;
};

export const getBirthYearsReactSelect = () => {
  let d = new Date();
  let y = d.getFullYear();

  let years = [];
  for (let i = y - 100; i <= y; i++) {
    years.push({ value: i, label: i });
  }
  return years;
};

export const getExpiryYearsReactSelect = () => {
  let d = new Date();
  let y = d.getFullYear();

  let years = [];
  for (let i = y; i <= y + 50; i++) {
    years.push({ value: i, label: i });
  }
  return years;
};

export const getUserRolesSelectListOptions = userRole =>
  Object.keys(userRoles).map(role => {
    if (userRole > userRoles[role]) {
      return (
        <option key={role} value={role}>
          {role}
        </option>
      );
    }
  });

export const getAccountStatusSelectListOptions = () =>
  Object.keys(accountStatuses).map(accKey => (
    <option key={accKey} value={accKey}>
      {accountStatuses[accKey]}
    </option>
  ));

export const getReducedAmount = (amount, reducer) => {
  return reducer.includes("%")
    ? amount - amount * (parseFloat(reducer) / 100)
    : amount - parseFloat(reducer);
};

export const getCategoryName = () => {
  if (window.location.href.includes("trading")) {
    return "MY ACCOUNTS";
  } else if (
    window.location.href.includes("funds") ||
    window.location.href.includes("trans")
  ) {
    return "FUNDING";
  } else if (window.location.href.includes("download")) {
    return "DOWNLOADS";
  } else if (window.location.href.includes("document")) {
    return "VERIFICATION";
  } else return "";
};

export const numberFormat = (number, decimals, decPoint, thousandsSep) => {
  number = (number + "").replace(/[^0-9+\-Ee.]/g, "");
  const n = !isFinite(+number) ? 0 : +number;
  const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
  const sep = typeof thousandsSep === "undefined" ? "," : thousandsSep;
  const dec = typeof decPoint === "undefined" ? "." : decPoint;
  let s = "";
  const toFixedFix = function (n, prec) {
    if (("" + n).indexOf("e") === -1) {
      return +(Math.round(n + "e+" + prec) + "e-" + prec);
    } else {
      const arr = ("" + n).split("e");
      let sig = "";
      if (+arr[1] + prec > 0) {
        sig = "+";
      }
      return (+(
        Math.round(+arr[0] + "e" + sig + (+arr[1] + prec)) +
        "e-" +
        prec
      )).toFixed(prec);
    }
  };
  s = (prec ? toFixedFix(n, prec).toString() : "" + Math.round(n)).split(".");
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || "").length < prec) {
    s[1] = s[1] || "";
    s[1] += new Array(prec - s[1].length + 1).join("0");
  }
  return s.join(dec);
};

export const capitalize = s => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const clearLocalStorage = () => {
  localStorage.clear();
};
