import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import jwt_decode from "jwt-decode";
import setAuthToken from "./redux/utils/setAuthToken";
import {
  getUserStatus,
  setCurrentUser,
  logoutUser,
} from "./redux/actions/authActions";
import {
  getSidebarCounts,
  setMenuCounters,
} from "./redux/actions/adminActions";
import { Provider } from "react-redux";
import store from "./redux/store";
import Layout from "./hoc/Layout/Layout";

// Admin section
import AdminRoute from "./components/AdminRoute/AdminRoute";
import AdminDashboard from "./containers/Admin/Dashboard/Dashboard";
import AdminUsers from "./containers/Admin/Users/UserList/UserList";
import AdminLawyers from "./containers/Admin/Lawyers/LawyerList/LawyerList";
import AdminCompanies from "./containers/Admin/Lawyers/CompanyList/CompanyList";
import AdminProfile from "./containers/Admin/Users/Profile/Profile";
import AdminAddUser from "./containers/Admin/Users/AddUser/AddUser";
import AdminProjects from "./containers/Admin/Projects/ProjectList/ProjectList";
import AdminProjectDashboard from "./containers/Admin/Projects/Dashboard/Dashboard";
import Claim from "./containers/Admin/Claims/Claim";
import Claims from "./containers/Admin/Claims/Claims";

// User section
import Login from "./containers/Auth/Login/Login";
import ConfirmEmail from "./containers/Auth/ConfirmEmail/ConfirmEmail";
import ForgotPassword from "./containers/Auth/ForgotPassword/ForgotPassword";
import ResetPassword from "./containers/Auth/ResetPassword/ResetPassword";

import "./App.css";

// PLUGINS
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
// import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "react-notifications/lib/notifications.css";

// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);
  // Decode token and get user info and exp
  const decoded = jwt_decode(token);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
  // Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Redirect to login
    window.location.href = "/login";
  }
}

class App extends Component {
  state = {
    newRequests: {
      newUsers: 0,
      draftClaims: 0,
      newClaims: 0,
      pendingClaims: 0,
      prereservedProjects: 0,
    },
  };

  triggerReadNewRequests = async () => {
    if (localStorage.jwtToken) {
      let status = "";

      await getUserStatus()
        .then(res => {
          status = res.data.status;
          if (status !== "new" && status !== "approved") {
            store.dispatch(logoutUser());
          }
        })
        .catch(err => {
          store.dispatch(logoutUser());
          console.log(err);
        });

      if (status === "new" || status === "approved") {
        await getSidebarCounts()
          .then(res => {
            const newRequests = res.data;
            if (
              JSON.stringify(this.state.newRequests) !==
              JSON.stringify(newRequests)
            ) {
              const newState = {
                newRequests,
              };

              store.dispatch(setMenuCounters(newRequests));
              this.setState(newState);
            }
          })
          .catch(err => console.log(err));
      }

      setTimeout(this.triggerReadNewRequests, 10000);
    }
  };

  componentDidMount() {
    this.triggerReadNewRequests().catch(err => console.log(err));
  }

  render() {
    return (
      <Provider store={store}>
        <Router>
          <Layout>
            <Route path="/login" component={Login} />
            <Route path="/forgot-password" component={ForgotPassword} />

            <Switch>
              {/* ADMIN ROUTES */}
              <AdminRoute exact path="/" component={AdminDashboard} />
              <AdminRoute exact path="/users/:status" component={AdminUsers} />
              <AdminRoute
                exact
                path="/user/profile/:id"
                component={AdminProfile}
              />
              <AdminRoute exact path="/add-user" component={AdminAddUser} />
              <AdminRoute
                exact
                path="/legals/companies"
                component={AdminCompanies}
              />
              <AdminRoute
                exact
                path="/legals/lawyers"
                component={AdminLawyers}
              />
              <AdminRoute
                exact
                path="/projects/:status"
                component={AdminProjects}
              />
              <AdminRoute
                exact
                path="/project/:id"
                component={AdminProjectDashboard}
              />
              <AdminRoute exact path="/claim/edit/:claimId" component={Claim} />
              <AdminRoute exact path="/claims/:status" component={Claims} />
            </Switch>

            <Route path="/email/confirm/:id+" component={ConfirmEmail} />
            <Route
              path="/email/confirm/:setpassword/:id+"
              component={ConfirmEmail}
            />
            <Route path="/resetpassword/:id+" component={ResetPassword} />
          </Layout>
        </Router>
      </Provider>
    );
  }
}

export default App;
