import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import {
  GET_ERRORS,
  GET_SUCCESS,
  SET_CURRENT_USER,
  USER_LOADING,
} from "./types";
import { API_URL } from "../../config/config";
import { clearLocalStorage } from "../../utils/utils";

// Get user status
export const getUserStatus = () => {
  return axios.get(`${API_URL}/api/users/getUserStatus/`);
};

// Login - get user token
export const loginUser = userData => dispatch => {
  axios
    .post(API_URL + "/api/users/loginAdmin", userData)
    .then(res => {
      // Remove registration steps data if exists
      console.log("CLEARING localStorage at login");
      clearLocalStorage();
      // Save to localStorage
      // Set token to localStorage
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
      localStorage.setItem("userData", JSON.stringify(decoded));
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Forgot password
export const resetPassword = email => dispatch => {
  axios
    .post(API_URL + "/api/users/resetPassword", { email })
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// New password
export const newPassword = (id, password) => dispatch => {
  axios
    .post(API_URL + "/api/users/newPassword", { id, password })
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Set logged in user
export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

// User loading
export const setUserLoading = () => {
  return {
    type: USER_LOADING,
  };
};

// Log user out
export const logoutUser = () => dispatch => {
  console.log("CLEARING localStorage at logout");
  clearLocalStorage();
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};
