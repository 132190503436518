import React, { useState, useRef } from "react";
import { Button, Card, Form, Modal } from "react-bootstrap";
import classes from "./Dashboard.module.css";
import { processProject } from "../../../../redux/actions/adminActions";
import IconRejected from "../../../../assets/images/icon-rejected.svg";
import IconApproved from "../../../../assets/images/icon-approved.svg";
import { NotificationManager } from "react-notifications";

const Statuses = props => {
  const inputReason = useRef("");
  const [state, setState] = useState({
    disableBtn: false,
    showReasonModal: false,
    errors: "",
  });

  const handleProcessUser = (status, reason) => {
    if (status === "pending" && reason === "") {
      createNotification("error", "Reason is required.");
      return false;
    }

    if (
      window.confirm(
        `Are you sure you want to change user status to ${status}?`
      )
    ) {
      setState(prevState => {
        return {
          ...prevState,
          disable: true,
        };
      });

      processProject({ projectId: props.projectId, status, reason })
        .then(res => {
          if (res.data.success) window.location.reload();
          else createNotification("error", res.data.error);
        })
        .catch(err => console.log(err));
    }
  };

  const handleShowModal = () => {
    setState(prevState => {
      return {
        ...prevState,
        showReasonModal: true,
      };
    });
  };

  const handleCloseModal = () => {
    setState(prevState => {
      return {
        ...prevState,
        showReasonModal: false,
      };
    });
  };

  const createNotification = (type, message = false) => {
    switch (type) {
      case "success":
        NotificationManager.success(
          "You have successfully updated the project status.",
          "Claim Update",
          4000
        );
        break;
      case "error":
        NotificationManager.error(
          message || "Something went wrong!",
          "Error",
          5000
        );
        break;
      default:
    }
  };

  return (
    <>
      <Card className={classes.card}>
        <Card.Body className="p-0">
          <p
            className={[
              "p-3 m-0 border-bottom fontWeight500",
              classes.cardHeader,
            ].join(" ")}
          >
            Statuses
          </p>

          <Form.Text style={{ color: "red" }} className="my-1 pl-3">
            {state.errors}
            <br />
          </Form.Text>

          <Button
            className="statusFlag statusFlagMedium statusFlagApproved border-0 text-dark fontWeight500 mx-4"
            disabled={state.disableBtn}
            onClick={() => handleProcessUser("approved", "")}
          >
            <img src={IconApproved} alt="" /> Approve
          </Button>
          <Button
            className="statusFlag statusFlagMedium statusFlagRejected border-0 text-dark fontWeight500 mx-4"
            disabled={state.disableBtn}
            onClick={handleShowModal}
          >
            <img src={IconRejected} alt="" /> Reject
          </Button>
        </Card.Body>
      </Card>

      <Modal show={state.showReasonModal} onHide={handleCloseModal} size="md">
        <Modal.Header closeButton>
          <Modal.Title>Reason</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <strong>Project Name: </strong> {props.projectData.projectName}
          <br />
          <br />
          <Form.Group>
            <Form.Control type="text" ref={inputReason} />
          </Form.Group>
          <Form.Row className="justify-content-between">
            <Button
              className="statusFlag statusFlagMedium statusFlagApproved border-0 text-dark fontWeight500 mx-4"
              disabled={state.disableBtn}
              onClick={() =>
                handleProcessUser("rejected", inputReason.current.value)
              }
            >
              <img src={IconApproved} alt="" /> Submit
            </Button>

            <Button
              className="statusFlag statusFlagMedium statusFlagRejected border-0 text-dark fontWeight500 mx-4"
              disabled={state.disableBtn}
              onClick={handleCloseModal}
            >
              <img src={IconRejected} alt="" /> Discard
            </Button>
          </Form.Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Statuses;
