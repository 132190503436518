import React, { useState, useEffect, useRef } from "react";
import classes from "./Dashboard.module.css";
import {
  getProjectLogs,
  addProjectLog,
} from "../../../../redux/actions/adminActions";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Button, Card, Form, Modal } from "react-bootstrap";

const ProjectLogs = props => {
  const [logs, setLogs] = useState({});
  const [state, setState] = useState({
    disableBtn: false,
    showLogModal: false,
  });
  const inputLog = useRef("");

  useEffect(() => {
    triggerGetProjectLogs();
  }, [props.projectId]);

  const triggerGetProjectLogs = () => {
    getProjectLogs(props.projectId)
      .then(res => {
        setLogs(res.data);
      })
      .catch(err => console.log(err));
  };

  const handleAddLog = event => {
    if (event === "") {
      alert("Event is required.");
      return false;
    }

    addProjectLog({
      projectId: props.projectId,
      event,
    })
      .then(res => {
        handleCloseModal();
        if (res.data) {
          triggerGetProjectLogs();
        } else alert("ERROR!");
      })
      .catch(err => console.log(err));
  };

  const handleShowModal = () => {
    setState(prevState => {
      return {
        ...prevState,
        showLogModal: true,
      };
    });
  };

  const handleCloseModal = () => {
    setState(prevState => {
      return {
        ...prevState,
        showLogModal: false,
      };
    });
  };

  const columnsProjectLog = [
    {
      dataField: "timestamp",
      text: "DATE",
      sort: true,
      classes: "breakWord",
    },
    {
      dataField: "event",
      text: "EVENT",
      classes: "breakWord",
    },
    {
      dataField: "actionedBy",
      text: "ACTIONED BY",
      sort: true,
      classes: "breakWord",
    },
  ];

  const defaultSorted = [
    {
      dataField: "timestamp",
      order: "desc",
    },
  ];

  const pagination = paginationFactory({
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
    ],
  });

  let dataProjectLog = [];
  if (!!logs) {
    for (let i = 0; i < logs.length; i++) {
      if (Number.isInteger(logs[i].timestamp)) {
        logs[i].timestamp = moment(logs[i].timestamp * 1000)
          .format("YYYY/MM/DD HH:mm:ss")
          .toString();
      }

      if (logs[i].customName) logs[i].actionedBy = logs[i].customName;

      dataProjectLog.push(logs[i]);
    }
  }

  return (
    <>
      <Card className={classes.card}>
        <Card.Body className="p-0 pb-2">
          <p
            className={[
              "p-3 m-0 border-bottom fontWeight500",
              classes.cardHeader,
            ].join(" ")}
          >
            Project Logs
            <Button
              type="button"
              variant="link"
              className="smallGreenButton mt-1 px-2 text-white"
              onClick={handleShowModal}
            >
              + ADD
            </Button>
          </p>
          <BootstrapTable
            bootstrap4
            bordered={false}
            keyField="_id"
            data={dataProjectLog}
            columns={columnsProjectLog}
            defaultSorted={defaultSorted}
            hover={true}
            pagination={pagination}
            classes={classes.table}
            headerClasses={classes.tableHeaderClass}
          />
        </Card.Body>
      </Card>

      <Modal show={state.showLogModal} onHide={handleCloseModal} size="md">
        <Modal.Header closeButton>
          <Modal.Title>Add log entry</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form.Group>
            <Form.Control type="text" ref={inputLog} />
          </Form.Group>

          <Form.Row className="justify-content-between">
            <Button
              className="blueButton font-weight-normal mx-4"
              disabled={state.disableBtn}
              onClick={() => handleAddLog(inputLog.current.value)}
            >
              Submit
            </Button>

            <Button
              className="orangeButton text-dark font-weight-normal mx-4"
              disabled={state.disableBtn}
              onClick={handleCloseModal}
            >
              Discard
            </Button>
          </Form.Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProjectLogs;
